import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

interface SliderBlockStyledProps {
  $src?: string;
  $dark?: boolean;
  $active?: boolean;
}
export const SliderBlockStyled = styled.div`
  position: relative;

  .swiper {
    width: 100%;
    overflow-x: hidden;
  }

  .swiper-wrapper {
    display: flex;

    > div {
      min-width: 100%;

    }
  }
`;

const NavigatorBtnShared = css`
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
`;

export const ArrowNavigator = styled.div`
  position: absolute;
  inset-block-start: 50%;
  width: 100%;
  transform: translateY(-50%);
  display: none;
  pointer-events: none;
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    ${NavigatorBtnShared}
    justify-content: space-between;
  }
`;

export const DotNavigator = styled.div`
  ${NavigatorBtnShared}
  inset-block-end: 56px;
  display: flex;
  justify-content: space-between;
`;

export const DotNavigatorInner = styled.div`
  display: flex;
  margin-inline: auto;
`;

const ButtonShared = css<SliderBlockStyledProps>`
  cursor: pointer;
  background: none;
  border: none;
  pointer-events: auto;
`;

export const ArrowBtn = styled.button<SliderBlockStyledProps>`
  ${ButtonShared}

  pointer-events: auto;
  width: 46px;
  height: 46px;

  :first-child {
    transform: rotate(90deg);

    &:active {
      transform: rotate(90deg) scale(0.96);
    }
  }

  :last-child {
    transform: rotate(-90deg);

    &:active {
      transform: rotate(-90deg) scale(0.96);
    }
  }

  svg {
    filter: drop-shadow(0 3px 2px rgb(0 0 0 / 70%));

    path {
      fill: ${colors.white};
    }
  }

  &:hover {
    svg path {
      fill: ${colors.gray3};
    }
  }

  &:active {
    transform: scale(0.96);
  }
`;

export const DotBtn = styled.button<SliderBlockStyledProps>`
  ${ButtonShared}

  span {
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: ${colors.gray2};

    &:hover {
      background: ${colors.gray4};
    }

    ${({ $active }) =>
      $active
      && css`
        pointer-events: none;
        background: ${colors.white};

        &:hover {
          background: ${colors.white};
        }
      `}
  }
`;
